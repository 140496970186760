<template>
    <div>
        <div class="grid">
            <div class="row">
                <div class="col l-6 m-6 c-12">
                    <div class="form-control" style="padding-top:20px;">
                        <span class="btn-file"> Chọn ảnh
                            <input type="file" id="fileUpload" multiple ref="fileUpload" v-on:change="upload()">
                        </span>
                    </div>
                </div>
            </div>
            <div class="row" style="padding: 30px 0;">
                <div class="col l-12 m-12 c-12">
                    <table class="data-table" style="min-height: 150px !important;">
                        <thead>
                            <tr>
                                <th style="width: 80px;">#</th>
                                <th style="width: 140px;">Ảnh</th>
                                <th style="width: calc(100vw - 280px);">Tên file</th>
                                <th style="width: 15%;">Ngày tạo</th>
                                <th style="width: 60px;">Hành động</th>
                            </tr>
                        </thead>
                        <tbody v-if="files.length == 0" style="height: 150px;">
                            <tr><td colspan="100" class="no-data"><span>Không có file</span></td></tr>
                        </tbody>
                        <tbody>
                            <tr v-for="(item, index) in files" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                <td class="center">{{index + 1}}</td>
                                <td class="center">
                                    <a @click="view(item)">
                                        <img v-if="item.fileExt == 'pdf' || item.fileExt == 'PDF'" class="box-img" src="../assets/images/pdf.svg" />
                                        <img v-else-if="item.fileExt == 'xlsx' || item.fileExt == 'XLS' || item.fileExt == 'xls' || item.fileExt == 'XLSX'" class="box-img" src="../assets/images/excel.svg" />
                                        <img v-else-if="item.fileExt == 'docx' || item.fileExt == 'DOCX'" class="box-img" src="../assets/images/word.svg" />
                                        <img v-else class="box-img" :src="item.fullPath" />
                                    </a>
                                </td>
                                <td class="left"><a @click="view(item)">{{item.fileName}}</a></td>
                                <td class="center">{{item.createdTime}}</td>
                                <td class="action">
                                    <md-button @click="delFile(item)" class="md-fab md-mini md-plain">
                                        <md-icon>clear</md-icon>
                                        <md-tooltip>Xóa</md-tooltip>
                                    </md-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import uploadService from '../api/uploadService';
    import messageBox from '../utils/messageBox';
    import { mapActions } from 'vuex';

    export default {
        props: [ 'updateFileInParent' ],

        data: () => ({
            files: [],
        }),

        created() {
        },

        methods: {
            ...mapActions('common', ['setLoading']),

            view(obj){
                const link = document.createElement('a')
                link.href = obj.fullPath;
                link.target = '_blank';
                link.click()
                URL.revokeObjectURL(link.href)
            },

            loadData(files){
                this.files = files;
            },

            delFile(item){
                const index = this.files.findIndex(x => x.id == item.id);
                this.files.splice(index, 1);
                this.updateFileInParent(this.files);
            },

            upload() {
                for(let i = 0; i < this.$refs.fileUpload.files.length; i ++){
                    let file = this.$refs.fileUpload.files[i];
                    this.setLoading(true);
                    let formData = new FormData();
                    formData.append('files[0]', file);

                    uploadService.uploadFile(formData).then((response) => {
                        if(response.data.statusCode == 200){
                            this.files.push(response.data.data);
                            this.updateFileInParent(this.files);
                            messageBox.showMessage("Upload thành công");
                        }
                        else {
                            messageBox.showWarning(response.data.data);
                        }
                    }).finally(() => { this.setLoading(false)});
                }
            },
        },
    }
</script>

<style scoped>
    .box-img {
        width: 120px;
        height: 60px;
    }
</style>